import { useMemo, useState } from 'react'
import { Flat } from 'types/domain'
import { toSorted } from 'utils/array'
import { keys } from 'utils/object'
import { z } from 'zod'

export const rangeSchema = z.object({
  min: z.number(),
  max: z.number(),
})

export const filtersSchema = z.object({
  rooms: z.array(z.number()),
  floor: z.array(z.string()),
  area: rangeSchema,
})

export type Filters = z.infer<typeof filtersSchema>

const useFilters = (flats: Flat[]) => {
  const availableFilters: Filters = useMemo(
    () => ({
      rooms: toSorted([...new Set(flats.map((flat) => flat.rooms))]),
      floor: toSorted([...new Set(flats.map((flat) => flat.floor))]),
      area: {
        min: Math.floor(Math.min(...flats.map((flat) => flat.area))),
        max: Math.ceil(Math.max(...flats.map((flat) => flat.area))),
      },
    }),
    [flats]
  )

  const [currentFilters, setCurrentFilters] = useState<Filters>({
    ...availableFilters,
  })

  const setRoomsFilters = (rooms: Filters['rooms']) => {
    setCurrentFilters((prev) => ({ ...prev, rooms: toSorted([...rooms]) }))
  }

  const setFloorFilters = (floor: Filters['floor']) => {
    setCurrentFilters((prev) => ({ ...prev, floor: toSorted([...floor]) }))
  }

  const setAreaFilters = (area: Filters['area']) => {
    setCurrentFilters((prev) => ({ ...prev, area }))
  }

  const filterFlats = (
    externalFlats: Flat[],
    filterBy: (keyof Filters)[] = keys(availableFilters)
  ) =>
    externalFlats
      .filter((flat) =>
        filterBy.includes('rooms')
          ? currentFilters.rooms.includes(flat.rooms)
          : true
      )
      .filter((flat) =>
        filterBy.includes('floor')
          ? currentFilters.floor.includes(flat.floor)
          : true
      )
      .filter((flat) =>
        filterBy.includes('area')
          ? flat.area >= currentFilters.area.min &&
            flat.area <= currentFilters.area.max
          : true
      )

  const filteredFlats = useMemo(
    () => filterFlats(flats),
    [flats, currentFilters]
  )

  const resetFilters = () => {
    setCurrentFilters(() => {
      return { ...availableFilters }
    })
  }

  return {
    currentFilters,
    availableFilters,
    filteredFlats,
    setAreaFilters,
    setFloorFilters,
    setRoomsFilters,
    setCurrentFilters,
    filterFlats,
    resetFilters,
  }
}

export default useFilters
